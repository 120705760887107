<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit="saveLevel" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Tên level (*):"
                label-for="input-1"
                description="Vd : level 1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  required
                  max="255"
                  placeholder="Tên level "
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Số bước quy đổi(*):"
                label-for="input-2"
                description="Số bước quy đổi"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.step_convert_to_boxes"
                  required
                  type="number"
                  min="0"
                  placeholder="Số bước quy đổi "
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Số hộp quy đổi (*):"
                label-for="input-3"
                description="(VD 1 hộp = 5 đồng)
"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.box_value"
                  required
                  min="0"
                  type="number"
                  placeholder="Số hộp quy đổi "
                ></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <b-card-group deck>
                    <b-card header="Cá nhân">
                      <b-form-group
                        id="input-group-4"
                        label="Số bước tối đa/ngày (*):"
                        label-for="input-4"
                        description="(VD 10000/ngày)
"
                      >
                        <b-form-input
                          id="input-4"
                          v-model="form.max_step_in_day"
                          required
                          type="number"
                          min="0"
                          placeholder="Số bước tối đa/ngày "
                        ></b-form-input>
                      </b-form-group>
                    </b-card>
                  </b-card-group>
                </div>
                <div class="col-md-6">
                  <b-card-group deck>
                    <b-card header="Nhóm" header-tag="header">
                      <b-form-group
                        id="input-group-5"
                        label="Số hộp quà tối đa
                                        nhận trong nhóm (*)"
                        label-for="input-5"
                        description="(VD 50 hộp)
"
                      >
                        <b-form-input
                          id="input-5"
                          v-model="form.group_max_get_boxes"
                          required
                          type="number"
                          min="0"
                          placeholder="Số hộp quà tối đa"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-6"
                        label="% số bước
                                        đóng góp nhóm
                                        (mặc định 100%) (*)"
                        label-for="input-6"
                        description="(VD 10%)
"
                      >
                        <b-form-input
                          id="input-6"
                          v-model="form.group_step_percent"
                          required
                          type="number"
                          min="0"
                          placeholder="% số bước đóng góp vào nhóm"
                        ></b-form-input>
                      </b-form-group>
                    </b-card>
                  </b-card-group>
                </div>
              </div>

              <b-form-group
                v-if="form.is_last_level != 1"
                id="input-group-7"
                label="Level tiếp theo (optional)"
                label-for="input-7"
                description="Có thể bỏ trống"
              >
                <b-form-select
                  id="input-7"
                  v-model="form.next_level_id"
                  :options="level_list"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-71"
                label="Level cuối :"
                label-for="input-71"
              >
                <b-form-checkbox
                  id="input-71"
                  v-model="form.is_last_level"
                  value="1"
                ></b-form-checkbox>
              </b-form-group>
              <div class="row form-group">
                <div class="col-md-12">
                  <b-card-group deck>
                    <b-card header="Nhiệm vụ">
                      <template v-for="(item, index) in mission_added">
                        <div class="row" v-bind:key="index">
                          <div class="col-md-12">
                            <b-form-group
                              :id="`input-group-ms-tt-${index + 1}`"
                              :label="`Tiêu đề nhiệm vụ ${index + 1}(*)`"
                              :label-for="`input-ms-tt-${index + 1}`"
                              description="Tiêu đề hiển thị"
                            >
                              <b-form-input
                                :id="`input-ms-n-${index + 1}`"
                                v-model="mission_added[index].title"
                                type="text"
                                maxlength="255"
                                :formatter="formatter"
                                required
                                autocomplete="off"
                                placeholder="Tiêu đề hiển thị"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-4">
                            <b-form-group
                              :id="`input-group-ms-${index + 1}`"
                              v-bind:label="`Chọn nhiệm vụ ${index + 1} (*)`"
                              :label-for="`input-ms-${index + 1}`"
                              description=""
                            >
                              <b-form-select
                                :id="`input-ms-${index + 1}`"
                                v-model="mission_added[index].mission_id"
                                :options="mission_list"
                              ></b-form-select>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <b-form-group
                              :id="`input-group-ms-n-${index + 1} (*)`"
                              label="Số lần(*)"
                              :label-for="`input-ms-n-${index + 1}`"
                              description="Số lần phải thực hiện nv này"
                            >
                              <b-form-input
                                :id="`input-ms-n-${index + 1}`"
                                v-model="mission_added[index].quantity"
                                autocomplete="off"
                                type="number"
                                min="0"
                                required
                                placeholder="Số lần thực hiện nv"
                              ></b-form-input>
                            </b-form-group>
                          </div>

                          <div class="col-md-2">
                            <b-form-group
                              :id="`input-group-ms-g-${index + 1} (*)`"
                              label="Phần thưởng(*)"
                              :label-for="`input-ms-g-${index + 1}`"
                              description="Nhập số hộp quà "
                            >
                              <b-form-input
                                :id="`input-ms-g-${index + 1}`"
                                v-model="mission_added[index].value"
                                required
                                type="number"
                                min="0"
                                autocomplete="off"
                                placeholder="số hộp quà thưởng "
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-3">
                            <b-form-group
                              :id="`input-group-ms-g1-${index + 1} (*)`"
                              label="Số bước đi(DAILY_WALK *)"
                              :label-for="`input-ms-g1-${index + 1}`"
                              description="Nhập số bước đi "
                            >
                              <b-form-input
                                :id="`input-ms-g1-${index + 1}`"
                                v-model="mission_added[index].num_step"
                                required
                                type="number"
                                min="0"
                                autocomplete="off"
                                placeholder="Nhập số bước"
                              ></b-form-input>
                            </b-form-group>
                          </div>

                          <div class="col-md-1" v-if="!is_view">
                            <b-button
                              v-if="
                                (mission_added.length > 1 && index != 0) ||
                                form.is_last_level == 1
                              "
                              @click="deleteMission(index)"
                              pill
                              variant="danger"
                              style="margin-top: 29px"
                              size="sm"
                              >Xóa
                            </b-button>
                          </div>
                        </div>
                      </template>

                      <div class="row" v-if="!is_view">
                        <b-button @click="addNewMission()" variant="default"
                          >Thêm nhiệm vụ</b-button
                        >
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <b-card-group deck>
                    <b-card header="Quyền lợi">
                      <b-table
                        table-class="datatable-table"
                        hover
                        tbody-tr-class="datatable-row"
                        thead-tr-class="datatable-row"
                        thead-class="datatable-head"
                        details-td-class="datatable-cell"
                        :items="interest"
                        :fields="fields"
                      >
                        <template #cell(icon)="data" v-if="!is_view">
                          <img
                            :src="data.item.icon"
                            style="width: 38px; height: 38px"
                          />
                        </template>

                        <template #cell(action)="data" v-if="!is_view">
                          <b-button
                            pill
                            variant="warning"
                            size="sm"
                            @click="editInterest(data.index, data.item)"
                            >Sửa
                          </b-button>

                          <b-button
                            @click="deleteInterest(data.index, data.item)"
                            pill
                            variant="danger"
                            size="sm"
                            >Xóa
                          </b-button>
                        </template>
                      </b-table>

                      <div class="row" v-if="!is_view">
                        <b-button @click="addNewInterest()" variant="default"
                          >Thêm quyền lợi</b-button
                        >
                      </div>
                    </b-card>
                  </b-card-group>
                </div>
              </div>

              <b-button v-if="!is_view" type="submit" variant="primary">{{
                is_edit ? "Cập nhập" : "Xác nhận"
              }}</b-button>
              <router-link v-if="is_edit && !is_view" :to="'/wallet-run/level'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <router-link v-if="is_view" :to="'/wallet-run/level'">
                <b-button variant="danger">Quay lại</b-button>
              </router-link>
              <b-button
                v-if="!is_view && !is_edit"
                type="reset"
                variant="danger"
                >Làm mới</b-button
              >
            </b-form>
          </div>
          <b-modal id="my-modal" hide-footer ref="myModal">
            <b-form class="col-12">
              <b-form-group label="icon(*):">
                <div style="display: flex">
                  <div style="height: 40px; margin-right: 20px">
                    <img
                      style="width: 38px; height: 38px"
                      :src="chill_interest.icon"
                      alt=""
                    />
                  </div>
                  <b-form-file
                    @change="onImageChange($event)"
                    placeholder="chọn icon"
                    type="file"
                  ></b-form-file>
                </div>
              </b-form-group>
              <b-form-group label="Thứ tự hiển thị(*):">
                <b-form-input
                  v-model="chill_interest.position"
                  placeholder="Thứ tự hiển thị "
                  type="number"
                  required
                  min="0"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Nhập tiêu đề quyền lợi(*):">
                <b-form-input
                  v-model="chill_interest.title"
                  required
                  placeholder="Nhập tiêu đề quyền lợi"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Nhập mô tả quyền lợi(*):">
                <b-form-textarea
                  v-model="chill_interest.desc"
                  required
                  placeholder="Nhập mô tả quyền lợi"
                ></b-form-textarea>
              </b-form-group>

              <b-row style="display: flex; justify-content: space-between">
                <b-button variant="primary" v-on:click="addChillInterest()"
                  >{{ is_edit_interest ? "Cập nhập" : "Thêm" }}
                </b-button>

                <b-button variant="danger" v-on:click="cancelAddChillInterest()"
                  >Hủy bỏ</b-button
                >
              </b-row>
            </b-form>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormMission",
  mixins: [Common],
  data() {
    return {
      form: {
        name: "",
        max_step_in_day: 0,
        step_convert_to_boxes: 0,
        box_value: 1,
        group_step_percent: 100,
        group_max_get_boxes: 0,
        data_mission: "",
        interests: "",
        next_level_id: "",
      },
      level_list: [{ text: "Chọn level tiếp theo", value: null }],
      mission_list: [{ text: "Chọn nhiệm vụ", value: "", disabled: true }],

      mission_added: [
        { mission_id: "", value: 0, quantity: 1, num_step: 0, title: "" },
      ],
      interest: [],
      chill_interest: {
        icon: "https://storage.googleapis.com/npay/images/coin-x6-bag-1653366903.png",
        title: "",
        position: "",
        desc: "",
      },
      fields: [
        {
          key: "position",
          label: "Thứ tự hiển thị",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "icon",
          label: "icon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Tiêu đề",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "desc",
          label: "Mô tả",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      num_mission: 2,
      show: true,
      is_edit: false,
      is_view: false,
      is_edit_interest: false,
      position_editing: "",
      is_last_level: 0,
      images: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí Level ", route: "/wallet-run/level" },
      {
        title: this.is_view
          ? "Xem Level"
          : this.is_edit
          ? "Sửa Level"
          : "Thêm Level",
      },
    ]);
  },
  methods: {
    formatter(value) {
      if (value.length > 255) {
        this.substring(0, 255);
      }
      return value;
    },
    async getList() {
      try {
        let params = {};
        params.limit = 100;
        let response = await WalletRunRepository.getListLevel(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.data) {
          let that = this;
          body.data.forEach((item) => {
            let lv_temp = { text: item.name, value: item.id };
            that.level_list.push(lv_temp);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    deleteMission(index) {
      if (confirm("Bạn có chắc muốn xóa nhiệm vụ này ?")) {
        this.mission_added.splice(index, 1);
      }
    },
    editInterest(position, item) {
      this.is_edit_interest = true;
      this.position_editing = position;
      let oldObject = JSON.stringify(item);
      let newObject = JSON.parse(oldObject);
      this.chill_interest = newObject;
      this.$refs["myModal"].show();
    },

    deleteInterest(position) {
      if (confirm("Bạn có chắc muốn xóa quyền lợi này ?")) {
        this.interest.splice(position, 1);
      }
    },
    addChillInterest() {
      let check = true;
      if (this.chill_interest.position.length === 0) {
        check = false;
      }
      if (this.chill_interest.position < 0) {
        alert('"Thứ tự hiển thị" không được nhỏ hơn 0');
        return false;
      }
      if (this.chill_interest.title.length === 0) {
        check = false;
      }

      if (this.chill_interest.desc.length === 0) {
        check = false;
      }
      if (!check) {
        let mgs = "Vui lòng nhập đầy đủ thông tin trước khi thêm quyền lợi";
        if (this.is_edit_interest) {
          mgs = "Vui lòng nhập đầy đủ thông tin trước khi cập nhật quyền lợi";
        }

        alert(mgs);
        return false;
      }

      let new_interrest = JSON.stringify(this.chill_interest);
      let newObj = JSON.parse(new_interrest);
      if (this.is_edit_interest) {
        this.interest[this.position_editing].icon = newObj.icon;
        this.interest[this.position_editing].title = newObj.title;
        this.interest[this.position_editing].position = newObj.position;
        this.interest[this.position_editing].desc = newObj.desc;
      } else {
        this.interest.push(newObj);
      }
      this.$refs["myModal"].hide();
      this.chill_interest.title = "";
      this.chill_interest.icon =
        "https://storage.googleapis.com/npay/images/coin-x6-bag-1653366903.png";
      this.chill_interest.desc = "";
      this.chill_interest.position = "";
      this.chill_interest.is_edit_interest = false;
      this.chill_interest.position_editing = "";
    },
    cancelAddChillInterest() {
      this.chill_interest.title = "";
      this.chill_interest.desc = "";
      this.$refs["myModal"].hide();
    },
    addNewMission() {
      let check = false;
      if (this.mission_added.length == 0 && this.form.is_last_level) {
        check = false;
      }
      if (
        this.mission_added.length > 0 &&
        this.mission_added[this.mission_added.length - 1].mission_id.length ===
          0
      ) {
        check = true;
      }
      if (
        this.mission_added.length > 0 &&
        this.mission_added[this.mission_added.length - 1].value.length === 0
      ) {
        check = true;
      }
      if (
        this.mission_added.length > 0 &&
        this.mission_added[this.mission_added.length - 1].quantity.length === 0
      ) {
        check = true;
      }
      if (
        this.mission_added.length > 0 &&
        this.mission_added[this.mission_added.length - 1].title.length === 0
      ) {
        check = true;
      }
      if (check) {
        alert("Vui lòng nhập đầy đủ thông tin trước khi thêm nhiệm vụ mới");
        return false;
      }
      if (
        this.mission_added[this.mission_added.length - 1].title.length > 255
      ) {
        alert("Tiêu đề nhiệm vụ không vượt quá 255 ký tự");
        return false;
      }
      this.removeMissionAddedFormList();

      let temp_ms = {
        mission_id: "",
        value: 1,
        quantity: 1,
        num_step: 0,
        title: "",
      };
      this.mission_added.push(temp_ms);
    },
    removeMissionAddedFormList() {
      let that = this;
      this.mission_added.forEach((item) => {
        that.mission_list.forEach((lsItem, index) => {
          if (item.mission_id == lsItem.value) {
            that.mission_list[index].disabled = true;
          }
        });
      });
    },
    addNewInterest() {
      this.is_edit_interest = false;
      this.$refs["myModal"].show();
    },
    async getListMission() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = {};
        params.limit = 1000;
        let response = await WalletRunRepository.getList(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.data) {
          this.items = body.data;
          this.items.forEach((item) => {
            let ms_op = {
              text: item.name,
              value: item.id,
            };
            this.mission_list.push(ms_op);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.imageIconPreview = URL.createObjectURL(files[0]);
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.images = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.chill_interest.icon = response.data.data.link;
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    saveLevel: async function () {
      if (
        this.mission_added.length > 0 &&
        this.mission_added[this.mission_added.length - 1].mission_id.length ===
          0 &&
        this.form.is_last_level != 1
      ) {
        return alert("Vui lòng chọn nhiệm vụ");
      }
      if (this.is_edit) {
        return this.updateLevel();
      }
      this.$bus.$emit("show-loading", true);
      this.form.data_mission = JSON.stringify(this.mission_added);
      this.form.interests = JSON.stringify(this.interest);
      let params = this.form;
      WalletRunRepository.createLevel(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            //alert(response.data.message);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            //alert(response.data.message);
            this.$router.push({ path: "/wallet-run/level" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          //alert("Có lỗi xảy ra");
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    updateLevel: async function () {
      this.$bus.$emit("show-loading", true);
      this.form.data_mission = JSON.stringify(this.mission_added);
      this.form.interests = JSON.stringify(this.interest);
      this.form.is_last_level = parseInt(this.form.is_last_level);
      if (this.form.is_last_level == 1) {
        this.form.next_level_id = "";
      }
      let params = this.form;
      WalletRunRepository.updateLevel(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            // alert(response.data.message);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            // alert(response.data.message);
            this.$router.push({ name: "level" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.name = "";
      this.form.code = "";
      this.form.status = 1;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async loadLevel(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletRunRepository.getLevel(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let res = body.data;
          this.form.name = res.name;
          this.form.max_step_in_day = res.max_step_in_day;
          this.form.step_convert_to_boxes = res.step_convert_to_boxes;
          this.form.box_value = res.box_value;
          this.form.group_step_percent = res.group_step_percent;
          this.form.group_max_get_boxes = res.group_max_get_boxes;
          this.form.next_level_id = res.next_level_id;
          this.form.is_last_level = res.is_last_level;
          if (this.form.is_last_level == 1) {
            this.mission_added = [];
          }
          if (res.level_has_mission.length > 0) {
            let that = this;
            that.mission_added = [];
            res.level_has_mission.forEach((item) => {
              let ms = {
                mission_id: item.mission_id,
                value: item.boxes,
                quantity: item.quantity,
                num_step: item.num_step,
                title: item.title,
              };
              that.mission_added.push(ms);
            });
          }
          if (res.interests) {
            let that = this;
            let re_interests = JSON.parse(res.interests);
            re_interests.forEach((item) => {
              let ms = {
                title: item.title,
                position: item.position,
                desc: item.desc,
                icon: item.icon,
              };
              that.interest.push(ms);
              this.chill_interest.icon = item.icon;
            });
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
  created() {
    this.getListMission();
    this.getList();
    if (this.$route.params.id) {
      if (this.$route.name == "level_view") {
        this.is_view = true;
      } else {
        this.is_edit = true;
      }
      this.loadLevel(this.$route.params.id);
    }
  },
};
</script>

<style scoped></style>
